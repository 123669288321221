// disable all for now - it's required for login, not otherwise (not load @apollo)

// import React from 'react'
// import { Router } from "@reach/router"
// import { Link, navigate } from "gatsby"
// import { Helmet } from "react-helmet"
// import Layout from '../layouts/layout'
// import DashboardIndex from "../profile"
// import UserArea from "../profile/UserArea"
// import Wrapper from '../templates/wrapper'
// import { logout } from "../services/auth"

// import PrivateRoute from "../components/PrivateRoute"

// import LoginForm from "../components/forms/login-form"
// // import SignUpForm from "../components/SignUpForm"
// import { useAuth } from "../hooks/useAuth"
// import { UserProvider } from "../hooks/useUser"


// import Content2Cols from '../components/content/content-raw'

const Dashboard = () => {

    return ''; //disable tmp

    // const auth = useAuth();

    // let siteTitle = `Login`;

    // return (
        
    //     <Layout page={null} type="boxed single" source="page" headerStyle="simple">
    //         <Helmet encodeSpecialCharacters={false}>
    //             <meta charSet="utf-8" />
    //             <title>{siteTitle}</title>
    //         </Helmet>
    //         <Wrapper>
    //             {auth
    //             ?
    //             <Content2Cols>
    //             {
    //             auth.isLoggedIn() &&
    //             <>
    //             <h2>Dashboard</h2>
    //             <nav>
    //                 <Link to={"/profile/"}>Dashboard</Link> {" | "}
    //                 <Link to={"/profile/userarea/"}>User Area</Link> {" | "}
    //                 <a
    //                 href="/"
    //                 onClick={event => {
    //                     event.preventDefault()
    //                     logout(() => navigate(`/profile/`))
    //                 }}
    //                 >
    //                 log out
    //                 </a>
    //             </nav>
    //             </>
    //             }
    //             <div className={"DashboardMain"} >
    //                 <UserProvider>
    //                     <Router id="router">
    //                         <LoginForm path="/profile/login"/>
    //                         {/* <SignUpForm path="/dashboard/signup/"/> */}
    //                         <PrivateRoute path="/profile/" component={DashboardIndex}/> 
    //                         <PrivateRoute path="/profile/userarea/" component={UserArea}/>
    //                     </Router>
    //                 </UserProvider>
    //             </div>
    //             </Content2Cols>
    //             :
    //             null
    //             }
    //         </Wrapper>
    //     </Layout>
        
    // )
}


export default Dashboard

